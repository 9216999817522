import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { AppService } from '../../services/app.service';
import { RepairrequestService } from '../../services/repairrequest.service';

@Component({
  selector: 'app-step-defect',
  templateUrl: './step-defect.component.html',
  styleUrls: ['./step-defect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepDefectComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;

  constructor(
    private AppService: AppService,
    repairRequest: RepairrequestService
  ) {
    this.repairRequest = repairRequest
  }

  defectList: any = [];
  defect = "";

  ngOnInit(): void {

    if (this.step.isComplete) {
      if (this.repairRequest.Defect) {
        this.defect = this.repairRequest.Defect;
        this.onCompleteStep();
      }
    }
    this.AppService.getDefect(this.repairRequest.Outside, this.repairRequest.Object, this.repairRequest.Element)
      .subscribe(

        (val) => {
          console.log("getDefect successful value returned in body",
            val);

          this.defectList = val;

          console.log(this.defectList);

          if (this.defectList.length == 0) {
            console.log("lenght 0");
            return;
          }

          if (this.defectList.length == 1) {
            console.log("lenght 1");
            return;
          }
        },
        response => {
          console.log("getDefect error", response);
        },
        () => {
          console.log("getDefect completed.");
        }
      )
  }

  onItemChange(item) {
    this.repairRequest.Defect = this.defect;
    this.repairRequest.DefectName = this.defectList.find(Item => Item.itemValue === this.defect).itemName;

    this.onCompleteStep();
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

}
