import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { AngularResizedEventModule } from 'angular-resize-event';

import { AppComponent } from './app.component';

import { StepsComponent } from './components/steps/steps.component';
import { StepTemplateComponent } from './components/step-template/step-template.component';
import { StepZipcodeComponent } from './components/step-zipcode/step-zipcode.component';
import { StepContactinfoComponent } from './components/step-contactinfo/step-contactinfo.component';
import { StepInoutsideComponent } from './components/step-inoutside/step-inoutside.component';
import { StepLocationComponent } from './components/step-location/step-location.component';
import { StepElementComponent } from './components/step-element/step-element.component';
import { StepDefectComponent } from './components/step-defect/step-defect.component';
import { StepRemarksComponent } from './components/step-remarks/step-remarks.component';
import { StepPhotoComponent } from './components/step-photo/step-photo.component';
import { StepOverviewComponent } from './components/step-overview/step-overview.component';

import { PhotoManagerComponent } from './components/photo-manager/photo-manager.component';
import { PhotoUploadComponent } from './components/photo-upload/photo-upload.component';
import { PhotoDeleteComponent } from './components/photo-delete/photo-delete.component';

import { FormPageComponent } from './pages/form-page/form-page.component';
import { CompletePageComponent } from './pages/complete-page/complete-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

import { AppService } from './services/app.service';
import { RepairrequestService } from './services/repairrequest.service';
import { PhotoService } from './services/photo.service';

import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    AppComponent,
    FormPageComponent,
    StepsComponent,
    StepTemplateComponent,
    StepZipcodeComponent,
    StepContactinfoComponent,
    StepInoutsideComponent,
    StepLocationComponent,
    StepElementComponent,
    StepDefectComponent,
    StepRemarksComponent,
    StepPhotoComponent,
    StepOverviewComponent,
    PhotoManagerComponent,
    PhotoUploadComponent,
    PhotoDeleteComponent,
    CompletePageComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    AngularResizedEventModule
  ],
  providers: [AppService, RepairrequestService, PhotoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
