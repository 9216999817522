import { Component,  OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StepModel } from '../../models/step.model';
import { RepairrequestService } from '../../services/repairrequest.service';
import { StepsService } from 'src/app/services/steps.service';

@Component({
  selector: 'app-step-contactinfo',
  templateUrl: './step-contactinfo.component.html',
  styleUrls: ['./step-contactinfo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepContactinfoComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;
  
  constructor(repairRequest: RepairrequestService, private stepsService: StepsService
  ) {
    this.repairRequest = repairRequest
  }

  get contactEmail() {
    return this.contactinfo.get('contactEmail')
  }

  get contactPhone() {
    return this.contactinfo.get('contactPhone')
  }

  get contactName() {
    return this.contactinfo.get('contactName')
  }

  contactinfo = new FormGroup({
    contactName: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$")]),
    contactEmail: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    contactPhone: new FormControl('', [
      Validators.required,
      Validators.pattern("^((\\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$")])
  });


  ngOnInit(): void {
    console.log('stap 2');

    this.contactinfo.get('contactPhone').setValue(localStorage.getItem('currentPhone'));
    this.contactinfo.get('contactEmail').setValue(localStorage.getItem('currentEmail'));
    this.contactinfo.get('contactName').setValue(localStorage.getItem('currentName'));

    this.repairRequest.Phone = this.contactPhone.value;
    this.repairRequest.Email = this.contactEmail.value;
    this.repairRequest.Name = this.contactName.value;

    if (this.contactinfo.valid) {
      console.log("form valid init");
      this.stepsService.setCurrentStepCompleted();
    }

    this.contactinfo.get("contactPhone").valueChanges.subscribe(selectedValue => {
    });
    this.contactinfo.get("contactEmail").valueChanges.subscribe(selectedValue => {
    });
    this.contactinfo.get("contactName").valueChanges.subscribe(selectedValue => {
    });

    this.contactinfo.statusChanges.subscribe((status) => {
      console.log('statusChanges Status is ' + status);
      this.onChange();
    });

    console.log("step end init");
  }

  onChange() {

    if (this.contactinfo.invalid) {
      this.step.isComplete = false;
      console.log("form invalid");
      console.log(this.findInvalidControls());
    }

    if (this.contactinfo.valid) {
      this.step.isComplete = true;
      this.onCompleteStep();
    }
  }

  onCompleteStep() {
    if (this.contactinfo.invalid) {
      return;
    }

    this.repairRequest.Phone = this.contactPhone.value;
    this.repairRequest.Email = this.contactEmail.value;
    this.repairRequest.Name = this.contactName.value;

    localStorage.setItem('currentPhone', this.contactPhone.value);
    localStorage.setItem('currentEmail', this.contactEmail.value);
    localStorage.setItem('currentName', this.contactName.value);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.contactinfo.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}
