import { Component, ViewEncapsulation } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { RepairrequestService } from './services/repairrequest.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {
  repairRequest: RepairrequestService;

  constructor(
    repairRequest: RepairrequestService) {
    this.repairRequest = repairRequest;
  }
  onResized(event: ResizedEvent) {
    if (this.repairRequest.IframeEnabled) {
        var new_height = document.getElementById('app-page-container').scrollHeight;
        this.repairRequest.IframeLastChanged = Date.now();
        this.repairRequest.IframeResized = true;
        parent.postMessage(new_height, "*");
        console.log('resize-component');
    }
    else {
      console.log(this.repairRequest);
      console.log('resize-component-disabled');
    }
  }
}
