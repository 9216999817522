import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { AppService } from '../../services/app.service';
import { RepairrequestService } from '../../services/repairrequest.service';

@Component({
    selector: 'app-step-inoutside',
    templateUrl: './step-inoutside.component.html',
  styleUrls: ['./step-inoutside.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepInoutsideComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;

  constructor(
    private AppService: AppService,
    repairRequest: RepairrequestService
  ) {
    this.repairRequest = repairRequest
  }

  inoutside = "";
  inoutsideList: any = [];

  ngOnInit(): void {

    if (this.step.isComplete) {
      if (this.repairRequest.Outside == true) {
        this.inoutside = 'true';
        this.onCompleteStep();
      }
      else {
        if (this.repairRequest.OutsideName) {
          this.inoutside = 'false';
          this.onCompleteStep();
        }
      }
    }

    this.AppService.getInoutside()
      .subscribe(

        (val) => {
          console.log("getInoutside successful value returned in body",
            val);


          this.inoutsideList = val;

          console.log(this.inoutsideList);

          if (this.inoutsideList.length == 0) {
            console.log("lenght 0");
            return;
          }

          if (this.inoutsideList.length == 1) { 
            console.log("lenght 1");
            return;
          }
        },
        response => {
          console.log("getInoutside error", response);
        },
        () => {
          console.log("getInoutside completed.");
        }
      )
  }

  onItemChange(item) {
    if (this.inoutside == 'true') {
      this.repairRequest.Outside = true;
    }
    else {
      this.repairRequest.Outside = false;
    }
    this.repairRequest.OutsideName = this.inoutsideList.find(Item => Item.itemValue === this.inoutside).itemName;
    this.onCompleteStep();
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

}
