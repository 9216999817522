import { Injectable } from '@angular/core';
import { Timestamp } from 'rxjs';
import { Time } from '@angular/common';

@Injectable()
export class RepairrequestService {
    constructor() {
      this.RequestSubmitted = false;
  }

  RepairRequestID: string;
  ObjectRepairID: string;
  Address: string;

  Phone: string;
  Email: string;
  Name: string;
  Outside: boolean;
  Object: string;
  Element: string;
  Defect: string;

  OutsideName: string;
  ObjectName: string;
  ElementName: string;
  DefectName: string;

  Remarks: string;

  RequestSubmitted: boolean;

  PhotoNo: number;

  CompanyName: string;
  CompanyPhone: string;

  IframeEnabled: boolean;
  IframeResized: boolean;
  IframeLastChanged: number;
}
