<div class="step-contactinfo">
  <h2>
    Via welke contactgegevens kunnen wij of de aannemer u bereiken?
  </h2>


  <form class="contactinfo-form" [formGroup]="contactinfo">

    <div class="form-group">
      <label>Uw naam:</label>
      <input (change)="onChange()" type="text" class="form-control" formControlName="contactName" placeholder="Voer een naam in" autofocus />
    </div>

    <div class="form-group">
      <label>Uw e-mailadres:</label>
      <input (change)="onChange()" type="email" class="form-control" formControlName="contactEmail" placeholder="Voer een e-mailadres in" />
    </div>

    <div class="form-group">
      <label>Uw telefoonnummer:</label>
      <input (change)="onChange()" type="text" class="form-control" formControlName="contactPhone" placeholder="Voer een telefoonnummer in" />
    </div>

  </form>
</div>
