<div class="step-overview">
  <h2>
    Overzicht
  </h2>
  <div class="step-content-title">Adres  <mat-icon (click)="onStepClick('1')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.Address }}</div>
  <div class="step-content-title">Contactgegevens <mat-icon (click)="onStepClick('2')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.Name + ' ' + this.repairRequest.Email + ' ' + this.repairRequest.Phone }}</div>
  <div class="step-content-title">Locatie <mat-icon (click)="onStepClick('3')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.OutsideName }} {{ this.repairRequest.ObjectName }}</div>
  <div class="step-content-title">Element <mat-icon (click)="onStepClick('5')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.ElementName }}</div>
  <div class="step-content-title">Defect <mat-icon (click)="onStepClick('6')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.DefectName }}</div>
  <div class="step-content-title">Toelichting <mat-icon (click)="onStepClick('7')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.Remarks }}</div>
  <div class="step-content-title">Aantal toegevoegde foto's <mat-icon (click)="onStepClick('8')" class="icon edit step-icon">edit</mat-icon></div>
  <div class="step-content-label">{{ this.repairRequest.PhotoNo }}</div>

  <div class="step-content-title">Zijn bovenstaande gegevens correct?</div>
  <form [formGroup]="form" novalidate>

    <div *ngFor="let data of Data; let i=index">
      <label>
        <input type="checkbox" [value]="data.value" (change)="onCheckboxChange($event)" />
        {{data.name}}
      </label>
    </div>

    <p class="error" *ngIf="this.form.controls['checkArray'].errors?.required">
      U kunt alleen doorgaan als alle gegevens correct zijn. Zo niet, klik op <mat-icon>edit</mat-icon> om terug te gaan.
    </p>
  </form>
</div>
