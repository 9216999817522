<div class="form-page-container">
  <app-steps></app-steps>
  <div class="step-page-container">
    <div class="form-step">
      <ng-container [ngSwitch]="currentStep?.stepIndex">
        <ng-container *ngSwitchCase="'1'">
          <app-step-zipcode [step]="currentStep"></app-step-zipcode>
        </ng-container>
        <ng-container *ngSwitchCase="'2'">
          <app-step-contactinfo [step]="currentStep"></app-step-contactinfo>
        </ng-container>
        <ng-container *ngSwitchCase="'3'">
          <app-step-inoutside [step]="currentStep"></app-step-inoutside>
        </ng-container>
        <ng-container *ngSwitchCase="'4'">
          <app-step-location [step]="currentStep"></app-step-location>
        </ng-container>
        <ng-container *ngSwitchCase="'5'">
          <app-step-element [step]="currentStep"></app-step-element>
        </ng-container>
        <ng-container *ngSwitchCase="'6'">
          <app-step-defect [step]="currentStep"></app-step-defect>
        </ng-container>
        <ng-container *ngSwitchCase="'7'">
          <app-step-remarks [step]="currentStep"></app-step-remarks>
        </ng-container>
        <ng-container *ngSwitchCase="'8'">
          <app-step-photo [step]="currentStep"></app-step-photo>
        </ng-container>
        <ng-container *ngSwitchCase="'9'">
          <app-step-overview [step]="currentStep"></app-step-overview>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <button (click)="onNextStep();" [disabled]="!currentStep?.isComplete || bttnClicked || requestSubmitted" class="form-continue-btn common-btn">
    {{ showButtonLabel() }}
  </button>
</div>
