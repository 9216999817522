import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { PhotoService } from '../../services/photo.service';
import { ProgressStatus, ProgressStatusEnum } from '../../models/progress-status.model';

@Component({
  selector: 'app-photo-delete',
  templateUrl: './photo-delete.component.html',
  styleUrls: ['./photo-delete.component.scss']
})

export class PhotoDeleteComponent {
  @Input() public disabled: boolean;
  @Input() public repairRequestFileID: string;
  @Output() public deleteStatus: EventEmitter<ProgressStatus>;

  constructor(private service: PhotoService) {
    this.deleteStatus = new EventEmitter<ProgressStatus>();
  }

  public delete() {
    this.deleteStatus.emit({ status: ProgressStatusEnum.START });
    this.service.deleteFile(this.repairRequestFileID).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.deleteStatus.emit({ status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100) });
            break;
          case HttpEventType.Response:
            this.deleteStatus.emit({ status: ProgressStatusEnum.COMPLETE });

            break;
        }
      },
      error => {
        this.deleteStatus.emit({ status: ProgressStatusEnum.ERROR });
      }
    );
  }
}
