import { Component, OnInit, Input } from '@angular/core';
import { PhotoService } from '../../services/photo.service';
import { ProgressStatus, ProgressStatusEnum } from '../../models/progress-status.model';
import { StepModel } from '../../models/step.model';
import { StepsService } from '../../services/steps.service';
import { RepairrequestService } from '../../services/repairrequest.service';

@Component({
  selector: 'app-photo-manager',
  templateUrl: './photo-manager.component.html',
  styleUrls: ['./photo-manager.component.scss']
})
export class PhotoManagerComponent implements OnInit {
  @Input() step: StepModel;

  repairRequest: RepairrequestService;

  public files: string[];
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDeleteError: boolean;
  public showUploadError: boolean;
  public showLimitError: boolean;
  
  public disableUpload: boolean;

  constructor(
    repairRequest: RepairrequestService,
    private service: PhotoService,
    private stepsService: StepsService) {
    this.repairRequest = repairRequest;
  }

  ngOnInit() {
    this.getFiles();
  }

  private getFiles() {
    this.service.getFiles().subscribe(
      data => {
        this.files = data;
        this.repairRequest.PhotoNo = this.files.length;
        if (this.files.length > 5) {
          this.disableUpload = true;
          this.showLimitError = true;
        }
        else {
          this.disableUpload = false;
          this.showLimitError = false;
        }
      }
    );
  }

  public deleteStatus(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showDeleteError = false;
        this.stepsService.setCurrentStepIncompleted();
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.percentage = event.percentage;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
        this.stepsService.setCurrentStepCompleted();
        this.getFiles();
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showDeleteError = true;
        this.stepsService.setCurrentStepCompleted();
        this.getFiles();
        break;
    }
  }

  public uploadStatus(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showUploadError = false;
        this.stepsService.setCurrentStepIncompleted();
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.percentage = event.percentage;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
        this.stepsService.setCurrentStepCompleted();
        this.getFiles();
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showUploadError = true;
        this.stepsService.setCurrentStepCompleted();
        this.getFiles();
        break;
    }
  }
}
