import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { StepsService } from '../../services/steps.service';
import { RepairrequestService } from '../../services/repairrequest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-step-photo',
    templateUrl: './step-photo.component.html',
    styleUrls: ['./step-photo.component.scss'],
    encapsulation: ViewEncapsulation.None
})
/** step-photo component*/
export class StepPhotoComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;


  constructor(repairRequest: RepairrequestService, private stepsService: StepsService
  ) {
    this.repairRequest = repairRequest

  }

  ngOnInit(): void {
    this.stepsService.setCurrentStepCompleted();
  }

  onCompleteStep() {

  }

}
