import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { StepsService } from '../../services/steps.service';
import { RepairrequestService } from '../../services/repairrequest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-step-overview',
  templateUrl: './step-overview.component.html',
  styleUrls: ['./step-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepOverviewComponent implements OnInit {

  @Input() step: StepModel;

  repairRequest: RepairrequestService;
  steps: Observable<StepModel[]>;
  form: FormGroup;
  agree = false;
  
  Data: Array<any> = [
    { name: 'Ja', value: 'true' }
  ];


  constructor(
    repairRequest: RepairrequestService,
    private stepsService: StepsService,
    private fb: FormBuilder
  ) {
    this.repairRequest = repairRequest;

    this.steps = this.stepsService.getSteps();

    this.form = this.fb.group({
      checkArray: this.fb.array([], [Validators.required])
    })
    };

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;

    if (e.target.checked) {
      this.agree = true;
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      this.agree = false;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    this.onCompleteStep();

  }

  ngOnInit(): void {
  }

  onCompleteStep() {
    if (this.agree) {
        this.step.isComplete = true;
    }
    else {
        this.step.isComplete = false;
    }
  }

  onStepClick(index) {

    this.stepsService.goToStep(index);
    
  }
}
