<div class="step-zipcode">
  <h2>Uw adres</h2>
  <!-- Postcode -->
  <section class="zipcode">
    <!-- Postcode form -->
    <div class="zipcode-details">
      <form class="zipcode-form" [formGroup]="zipcodeform">
        <fieldset>
          <div>
            <div>Uw postcode:</div>
            <input class="zipcode-input" name="zipcode-input" (change)="this.onChangeAddress()" formControlName="zipcodeField" type="text" placeholder="Voer uw postcode in" required autofocus>
          </div>
          <br />

          <div>
            <div>Uw huisnummer <strong>zonder toevoeging:</strong></div>
            <input class="housenumber-input" name="housenumber-input" (change)="this.onChangeAddress()" formControlName="housenumberField" type="text" placeholder="Voer uw huisnummer in" required>
          </div>
          <br />

          <div class="error-container" *ngIf="showError">De door u opgegeven postcode - huisnummercombinatie : <span>{{zipcode}} - {{housenumber}}</span> wordt niet gevonden. Probeer opnieuw. Lukt het niet? Bel ons dan!</div>
          <div>
            <button class="checkaddress-btn" (click)="onGetAddress()" [disabled]="zipcodeform.invalid">Controleer uw adres</button>
          </div>

          <div *ngIf="showHousenumberext">
            <br />
            <br />
            <div>Meerdere adressen gevonden, selecteer uw adres:</div>

            <ul class="radioList">
              <li class="custom-control custom-radio" *ngFor="let item of addressesFound">
                <input type="radio" class="custom-control-input" formControlName="housenumberextField" id="{{item.objectRepairID}}" value="{{item.objectRepairID}}" (change)="this.onClickHousenumberext()" mdbInput />
                <label class="custom-control-label" for="{{item.objectRepairID}}">{{item.street}} {{item.housenumber}} {{item.housenumberExt}}</label>
              </li>
            </ul>

            <div class="error-container" *ngIf="showError-ext">Selecteer een adres.</div>

          </div>
        </fieldset>
      </form>

      <br>
      <!-- List the addresses related to the postcode housenumber given -->
      <div class="zipcode-addresses" *ngIf="showAddresses">
        <div class="step-content-label">Klik op de button 'Verder' als onderstaand adres klopt:</div>
        {{ address }}
      </div>
    </div>
  </section>

</div>
