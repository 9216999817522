import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { AppService } from '../../services/app.service';
import { RepairrequestService } from '../../services/repairrequest.service';

@Component({
    selector: 'app-step-location',
    templateUrl: './step-location.component.html',
  styleUrls: ['./step-location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepLocationComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;

  constructor(
    private AppService: AppService,
    repairRequest: RepairrequestService
  ) {
    this.repairRequest = repairRequest
  }

  locationList: any = [];
  location = "";

  ngOnInit(): void {

    if (this.step.isComplete) {
      if (this.repairRequest.Object) {
        this.location = this.repairRequest.Object;
        this.onCompleteStep();
      }
    }

    this.AppService.getObject(this.repairRequest.Outside)
      .subscribe(

        (val) => {
          console.log("getObject successful value returned in body",
            val);

          this.locationList = val;

          console.log(this.locationList);

          if (this.locationList.length == 0) {
            console.log("lenght 0");
            return;
          }

          if (this.locationList.length == 1) {
            console.log("lenght 1");
            return;
          }
        },
        response => {
          console.log("getObject error", response);
        },
        () => {
          console.log("getObject completed.");
        }
      )
  }

  onItemChange(item) {
    this.repairRequest.Object = this.location;
    this.repairRequest.ObjectName = this.locationList.find(Item => Item.itemValue === this.location).itemName;

    this.onCompleteStep();
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

}
