<div class="step-location">
  <h2>Waar is het probleem?</h2>
  <div class="step-content-label">Kies een locatie:</div>
  <div>
    <ul class="radioList">
      <li class="custom-control custom-radio" *ngFor="let item of locationList">
        <input type="radio" class="custom-control-input" [(ngModel)]="location" name="list_location" id="{{item.itemValue}}" value="{{item.itemValue}}" (change)="onItemChange(item)" mdbInput />
        <label class="custom-control-label" for="{{item.itemValue}}">{{item.itemName}}</label>
      </li>
    </ul>
  </div>

</div>
