<app-photo-upload [disabled]="disableUpload || showProgress" (uploadStatus)="uploadStatus($event)"></app-photo-upload>

<div class="progress-container">
  <p *ngIf="showProgress"> Voortgang: <strong>{{percentage}}%</strong></p>
  <label class="error" [hidden]="!showDeleteError">Fout bij het verwijderen, probeer opnieuw.</label>
  <label class="error" [hidden]="!showUploadError">Fout bij het uploaden. U kunt alleen fotobestanden uploaden. Probeer het opnieuw!</label>
  <label class="error" [hidden]="!showLimitError">Het maximum van 6 foto's is bereikt. </label>
</div>

<div class="step-content-title">Toegevoegde foto's:</div>


<div class="photo-container">
  <ul>
    <li *ngFor="let file of files">
      <div class="container-photo-item">
        {{file.fileName}}
        <app-photo-delete [disabled]="showProgress" [repairRequestFileID]="file.repairRequestFileID" (deleteStatus)="deleteStatus($event)"></app-photo-delete>
      </div>
    </li>
  </ul>
</div>
