import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RepairrequestService } from './repairrequest.service';

@Injectable()
export class PhotoService {
  private baseApiUrl: string;
  private apiDeleteUrl: string;
  private apiUploadUrl: string;
  private apiFileUrl: string;
  repairRequest: RepairrequestService;

  constructor(
    private httpClient: HttpClient,
    repairRequest: RepairrequestService
  ) {
    this.baseApiUrl = 'https://' + location.host;
    this.apiDeleteUrl = this.baseApiUrl + '/api/RepairRequestFile/';
    this.apiUploadUrl = this.baseApiUrl + '/api/RepairRequestFile/';
    this.apiFileUrl = this.baseApiUrl + '/api/RepairRequestFile/';
    this.repairRequest = repairRequest;
  }

  public deleteFile(RepairRequestFileID: string): Observable<HttpEvent<Blob>> {
    var DeleteFileURL = this.apiDeleteUrl + this.repairRequest.RepairRequestID + '/' + RepairRequestFileID;
    return this.httpClient.request(new HttpRequest(
      'DELETE',
      DeleteFileURL,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  public uploadFile(file: Blob): Observable<HttpEvent<void>> {
    const formData = new FormData();
    formData.append('file', file);
    var UploadURL = this.apiUploadUrl + '?RepairRequestID=' + this.repairRequest.RepairRequestID;

    return this.httpClient.request(new HttpRequest(
      'POST',
      UploadURL,
      formData,
      {
        reportProgress: true
      }));
  }

  public getFiles(): Observable<string[]> {
    var GetFilesURL = this.apiFileUrl + this.repairRequest.RepairRequestID;
    return this.httpClient.get<string[]>(GetFilesURL);
  }
}
