import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { Subscription } from 'rxjs';
import { StepsService } from '../../services/steps.service';
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { RepairrequestService } from '../../services/repairrequest.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormPageComponent implements OnInit {
  currentStep: StepModel;
  currentStepSub: Subscription;
  repairRequest: RepairrequestService;
  bttnClicked = false;
  requestSubmitted = false;

  constructor(
    private stepsService: StepsService,
    private router: Router,
    private AppService: AppService,
    private titleService: Title,
    repairRequest: RepairrequestService
  )
  {
      this.repairRequest = repairRequest
  }
  

  ngOnInit(): void {
    this.currentStepSub = this.stepsService.getCurrentStep().subscribe((step: StepModel) => {
      this.currentStep = step;
    });

    this.bttnClicked = false;
    this.requestSubmitted = this.repairRequest.RequestSubmitted;

    //create session
    this.AppService.createRepairRequest().subscribe(
      (val) => {
        console.log("CreateRepairRequest successful value returned in body",
          val);
        this.repairRequest.RepairRequestID = val['repairRequestID'];
        this.titleService.setTitle(val['companyName'] + " - ReparatieMelden.nu");
        this.repairRequest.CompanyName = val['companyName'];
        this.repairRequest.CompanyPhone = val['companyPhone'];
        this.repairRequest.IframeEnabled = val['iframeEnabled'];
        if (val['iframeEnabled'] == true) {
          console.log('resize0');
          console.log(document.getElementById('app-page-container'));
          if (parent.location != window.location) {
            this.resizeWindow();
            this.repairRequest.IframeResized = true;
          }
        }
      },
      response => {
        console.log("CreateRepairRequest error", response);
        this.router.navigate(['/error']);
      },
      () => {
        console.log("CreateRepairRequest completed.");
      }
    ) 
  }

  onNextStep() {
    if (!this.stepsService.isLastStep()) {
      console.log('next step');
      
      this.stepsService.moveToNextStep();
      
    } else {
      this.bttnClicked = true;
      this.onSubmit();
    }
  }

  showButtonLabel() {
    return !this.stepsService.isLastStep() ? 'Verder' : 'Afronden';
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks and unexpected angular errors
    this.currentStepSub.unsubscribe();
  }

  resizeWindow(): void {
    var new_height = document.getElementById('app-page-container').scrollHeight;
    parent.postMessage(new_height, "*");
    console.log('resize-formpage');
  }

  onSubmit(): void {
    //afronden reparatie

    this.AppService.completeRepairRequest().subscribe(

      (val) => {
        console.log("CompleteRepairRequest successful value returned in body");
        this.repairRequest.RequestSubmitted = true;
        this.router.navigate(['/complete']);
      },
      response => {
        console.log("CompleteRepairRequest error", response);
        this.router.navigate(['/error']);
      },
      () => {
        console.log("CompleteRepairRequest completed.");
      }
    ) 
  }
}
