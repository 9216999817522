import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { AppService } from '../../services/app.service';
import { RepairrequestService } from '../../services/repairrequest.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-zipcode',
  templateUrl: './step-zipcode.component.html',
  styleUrls: ['./step-zipcode.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepZipcodeComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;

  showHousenumberext = false;
    
  addressesFound: any = [];
  showAddresses = false;
  address = "";

  showError = false;

  enableGetAddressesButton = false;

  constructor(
    private AppService: AppService,
    repairRequest: RepairrequestService
  ) {
    this.repairRequest = repairRequest
  }

  get zipcode() {
    return this.zipcodeform.get('zipcodeField').value
  }

  get housenumber() {
    return this.zipcodeform.get('housenumberField').value
  }

  get housenumberext() {
    if (!this.zipcodeform.get('housenumberextField'))
      return "";

    return this.zipcodeform.get('housenumberextField').value
  }

  set housenumberext(value) {
    this.zipcodeform.get('housenumberextField').setValue(value);
  }


  zipcodeform = new FormGroup({
    zipcodeField: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(7)
    ]),
    housenumberField: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(7)
    ]),
    housenumberextField: new FormControl('', [
      
    ])
  });


  ngOnInit(): void {

    this.zipcodeform.get("zipcodeField").valueChanges.subscribe(selectedValue => {
      console.log('zipcodeField value changed')
      this.onChangeAddress();
    });

    this.zipcodeform.get("housenumberField").valueChanges.subscribe(selectedValue => {
      console.log('housenumberField value changed')
      this.onChangeAddress();
    });
  }

  onChangeAddress() {
    this.showError = false;
    this.step.isComplete = false;
    this.showAddresses = false;
    this.housenumberext = "";
    this.showHousenumberext = false;
    return;
  }

  onClickHousenumberext() {

    var addressesSelected = this.addressesFound.find(Item => Item.objectRepairID === this.housenumberext);

    this.showError = false;

    this.address = addressesSelected.street + ' ' + addressesSelected.housenumber;
    if (addressesSelected.housenumberExt != null) {
      if (addressesSelected.housenumberExt.length > 0)
        this.address = this.address + ' ' + addressesSelected.housenumberExt;
    }
    this.address = this.address + ' ' + addressesSelected.city;

    this.showAddresses = true;
    this.step.isComplete = true;

    this.repairRequest.ObjectRepairID = addressesSelected.objectRepairID;
    this.repairRequest.Address = this.address;

    return;
  }

  /**
 * Request postcode details
 */
  onGetAddress() {
    var tmpZip = this.zipcode;
    this.AppService.getAddress(tmpZip.replace(/\s+/g, ''),this.housenumber,this.housenumberext)
      .subscribe(

        (val) => {
          console.log("getAddress successful value returned in body",
            val);


          this.addressesFound = val;

          console.log(this.addressesFound);

          if (this.addressesFound.length == 0) {
            this.showError = true;
            this.step.isComplete = false;
            this.showAddresses = false;
            this.showHousenumberext = false;
            this.repairRequest.ObjectRepairID = null;
            console.log("lenght 0");
            return;
          }

          if (this.addressesFound.length == 1) {
            console.log(this.addressesFound[0]);
            this.showError = false;

            this.address = this.addressesFound[0].street + ' ' + this.addressesFound[0].housenumber;
            if (this.addressesFound[0].housenumberExt != null) {
              if (this.addressesFound[0].housenumberExt.length > 0)
                this.address = this.address + ' ' + this.addressesFound[0].housenumberExt;
            }
            this.address = this.address + ' ' + this.addressesFound[0].city;

            this.showAddresses = true;
            this.step.isComplete = true;
            this.showHousenumberext = false;
            this.repairRequest.ObjectRepairID = this.addressesFound[0].objectRepairID;
            this.repairRequest.Address = this.address;
            console.log("lenght 1");
            return;
          }

          if (this.addressesFound.length > 1) {
            this.showError = false;
            this.showAddresses = false;
            this.step.isComplete = false;
            this.showHousenumberext = true;
            this.repairRequest.ObjectRepairID = null;
            console.log("lenght >1");
            return;
          }

          //this.addressesFound = val.;

          //this.repairRequest.RepairRequestID = val['repairRequestID'];
        },
        response => {
          console.log("getAddress error", response);
          this.showError = true;
          this.step.isComplete = false;
          this.showAddresses = false;
          this.showHousenumberext = false;
          this.repairRequest.ObjectRepairID = null;
          
        },
        () => {
          console.log("getAddress completed.");
        }
      )
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

}
