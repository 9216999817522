import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { AppService } from '../../services/app.service';
import { RepairrequestService } from '../../services/repairrequest.service';

@Component({
  selector: 'app-step-element',
  templateUrl: './step-element.component.html',
  styleUrls: ['./step-element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepElementComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;

  constructor(
    private AppService: AppService,
    repairRequest: RepairrequestService
  ) {
    this.repairRequest = repairRequest
  }

  elementList: any = [];
  element = "";

  ngOnInit(): void {
    if (this.step.isComplete) {
      if (this.repairRequest.Element) {
        this.element = this.repairRequest.Element;
        this.onCompleteStep();
      }
    }
    this.AppService.getElement(this.repairRequest.Outside, this.repairRequest.Object)
      .subscribe(

        (val) => {
          console.log("getElement successful value returned in body",
            val);

          this.elementList = val;

          console.log(this.elementList);

          if (this.elementList.length == 0) {
            console.log("lenght 0");
            return;
          }

          if (this.elementList.length == 1) {
            console.log("lenght 1");
            return;
          }
        },
        response => {
          console.log("getElement error", response);
        },
        () => {
          console.log("getElement completed.");
        }
      )
  }

  onItemChange(item) {
    this.repairRequest.Element = this.element;
    this.repairRequest.ElementName = this.elementList.find(Item => Item.itemValue === this.element).itemName;

    this.onCompleteStep();
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

}
