<div class="step-remarks">
  <h2>Wat is het probleem?</h2>
  <div class="step-content-label">
    Geef een duidelijke toelichting (minimaal 10 en maximaal 300 tekens). Dan kunnen wij u zo goed mogelijk helpen:
  </div>
  <form class="remarks-form" [formGroup]="remarksform">
    <textarea rows="4" cols="50" ([ngModel])="remarks" (ngModelChange)="this.onCompleteStep()" class="form-control"
              formControlName="remarksField"
              placeholder="Beschrijf de exacte locatie (bijvoorbeeld waar in de gang of dak(goot) het probleem zit). Geef, waar mogelijk, verdere toelichting wat er mis is."
              required>
    </textarea>

  </form>
</div>
