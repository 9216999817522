import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complete-page',
  templateUrl: './complete-page.component.html',
  styleUrls: ['./complete-page.component.scss']
})
export class CompletePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
