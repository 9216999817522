import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepModel } from '../models/step.model';

const STEPS = [
  { stepIndex: 1, isComplete: false },
  { stepIndex: 2, isComplete: false },
  { stepIndex: 3, isComplete: false },
  { stepIndex: 4, isComplete: false },
  { stepIndex: 5, isComplete: false },
  { stepIndex: 6, isComplete: false },
  { stepIndex: 7, isComplete: false },
  { stepIndex: 8, isComplete: true },
  { stepIndex: 9, isComplete: false }
];

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
  currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);

  constructor() {
    this.currentStep$.next(this.steps$.value[0]);

    this.currentStep$.subscribe((value) => {
      console.log('steps Changes:' + value.stepIndex);
      var steps = this.steps$.getValue();

      steps.forEach(stepElement => {
        console.log(stepElement);
        if (stepElement.stepIndex <= this.currentStep$.getValue().stepIndex) {
          console.log('overslaan');
          return;
        }
        if (stepElement.isComplete) {
          console.log('terugzetten');
          stepElement.isComplete = false;
        }
      });
    });
  }


  setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }

  setCurrentStepCompleted(): void {
    var steps = this.steps$.getValue();

    steps.forEach(stepElement => {
      console.log(stepElement);
      if (stepElement.stepIndex == this.currentStep$.getValue().stepIndex) {
        setTimeout(() => { stepElement.isComplete = true; }, 0);
        return;
      }
    });
  }

  setCurrentStepIncompleted(): void {
    var steps = this.steps$.getValue();

    steps.forEach(stepElement => {
      console.log(stepElement);
      if (stepElement.stepIndex == this.currentStep$.getValue().stepIndex) {
        setTimeout(() => { stepElement.isComplete = false; }, 0);
        return;
      }
    });
  }

  getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  getStep(index): Observable<StepModel> {
    return this.steps$[index-1].asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;
    console.log('next step log' + this.steps$);
    if (index < this.steps$.value.length) {
      this.currentStep$.next(this.steps$.value[index]);
    }
  }

  goToStep(index): void {
    console.log('go to step log' + index);
    var steps = this.steps$.getValue();

    steps.forEach(stepElement => {
      console.log(stepElement);
      if (stepElement.stepIndex != index) {
        console.log('overslaan');
        return;
      }

      var tmpStep = this.steps$.value[index];
      console.log(tmpStep);
      this.setCurrentStep(stepElement);
    });
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }
}
