import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RepairrequestService } from './repairrequest.service';

@Injectable()
export class AppService {
  repairRequest: RepairrequestService;
  baseURL: string = 'https://' + location.host;

  constructor(
    private http: HttpClient,
    repairRequest: RepairrequestService
  ) {
    this.repairRequest = repairRequest
  }


  /**
   * Request session
   */
  createRepairRequest() {
    return this.http.post(this.baseURL + '/api/RepairRequest',null);
  }

  completeRepairRequest() {

    return this.http.put(this.baseURL + '/api/RepairRequest/' + this.repairRequest.RepairRequestID, {
      "RepairRequestID": this.repairRequest.RepairRequestID,
      "ObjectRepairID": this.repairRequest.ObjectRepairID,
      "Name": this.repairRequest.Name,
      "Email": this.repairRequest.Email,
      "Phone": this.repairRequest.Phone,
      "Outside": this.repairRequest.Outside,
      "Object": this.repairRequest.Object,
      "Element": this.repairRequest.Element,
      "Defect": this.repairRequest.Defect,
      "Remarks": this.repairRequest.Remarks
    });
  }

  /**
     * Request address
     */
  getAddress(zipcode, housenumber, housenumberext) {
    return this.http.get(this.baseURL + '/api/zipcodeaddress?RepairRequestID=' + this.repairRequest.RepairRequestID + '&zipcode=' + zipcode + '&housenumber=' + housenumber + '&housenumberext=' + housenumberext);
  }

  getInoutside() {
    return this.http.get(this.baseURL + '/api/DecisionTreeRepairsInoutside?RepairRequestID=' + this.repairRequest.RepairRequestID);
  }

  getObject(outside) {
    return this.http.get(this.baseURL + '/api/DecisionTreeRepairsObject?RepairRequestID=' + this.repairRequest.RepairRequestID + "&outside=" + outside);
  }

  getElement(outside, object) {
    return this.http.get(this.baseURL + '/api/DecisionTreeRepairsElement?RepairRequestID=' + this.repairRequest.RepairRequestID + "&outside=" + outside + "&object=" + object);
  }

  getDefect(outside, object,element) {
    return this.http.get(this.baseURL + '/api/DecisionTreeRepairsDefect?RepairRequestID=' + this.repairRequest.RepairRequestID + "&outside=" + outside + "&object=" + object + "&element=" + element);
  }
}
