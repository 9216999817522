import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { RepairrequestService } from '../../services/repairrequest.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StepsService } from 'src/app/services/steps.service';

@Component({
    selector: 'app-step-remarks',
    templateUrl: './step-remarks.component.html',
  styleUrls: ['./step-remarks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepRemarksComponent implements OnInit {

  @Input() step: StepModel;
  repairRequest: RepairrequestService;

  constructor(repairRequest: RepairrequestService, private stepsService: StepsService
  ) {
    this.repairRequest = repairRequest
  }

  remarks = "";

  get remarksField() {
    return this.remarksform.get('remarksField')
  }

  remarksform = new FormGroup({
    remarksField: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(300)
    ])
  });

  ngOnInit(): void {
    this.remarksform.get('remarksField').setValue(this.repairRequest.Remarks);
    if (this.remarksform.valid) {
      console.log("form valid init");
      this.stepsService.setCurrentStepCompleted();
    }
  }

  onCompleteStep() {
    
    if (this.remarksform.invalid) {
      this.step.isComplete = false;
      console.log("invalid");
      return;
    }
    
    this.repairRequest.Remarks = this.remarksform.get('remarksField').value;
    this.step.isComplete = true;
    console.log("valid");
  }

}
